<template>
  <section>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-start">
        <template v-if="!is_editting">
          <h2>{{ category.name }}</h2>
          <b-button variant="warning" class="btn-icon ml-1" size="sm"
            @click="is_editting = true"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </template>

        <template v-else>
          <b-form-input v-model="category.name" />
          <update-button @update="updateCategory" :is-loading="is_updating" class="ml-50" size="md" />
        </template>
      </div>
      <lesson-create-form :category="category" :display_order="lessons.length - 1" />
    </div>
    <b-row class="mt-1">
      <b-col cols="3" v-for="(lesson) in lessons" :key="lesson._id">
        <lesson-card :lesson="lesson" />
      </b-col>
    </b-row>
    <b-pagination class="mt-auto" pills size="lg" align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </section>
</template>

<script>
import service from '../../service'
import LessonCreateForm from './LessonCreateForm.vue'
import LessonCard from './LessonCard.vue'

export default {
  components: {
    LessonCreateForm,
    LessonCard,
  },
  props: {
    category: { type: Object, required: true },
  },
  data() {
    return {
      is_editting: false,
      is_updating: false,
      lessons: [],
      current_page: 1,
      total_items: 0,
      items_perpage: 90,
    }
  },
  watch: {
    current_page() {
      this.getLessonList()
    },
  },
  created() {
    this.getLessonList()
  },
  methods: {
    async updateCategory() {
      this.is_updating = true
      await service.updateCategory({ data: JSON.stringify(this.category) })
      this.is_updating = false
      this.is_editting = false
    },
    async getLessonList() {
      let result = await service.getList({
        page: this.current_page,
        limit: this.items_perpage,
        query: encodeURIComponent(JSON.stringify({ category_key: this.category.key })),
        order_by: 'display_order',
        order: 1
      })
      this.lessons = result.list
      this.total_items = result.total
    },
  },
}
</script>
