<template>
  <b-button variant="primary" @click="create">
    <b-spinner v-if="isLoading" small class="mr-50" />
    <feather-icon v-else
      icon="PlusIcon"
      class="mr-50"
    />
    <span class="align-middle">Add Lesson</span>
  </b-button>
</template>

<script>
import service from '../../service'

export default {
  props: {
    category: { type: Object, required: true },
    display_order: { type: Number, required: true },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    async create() {
      this.isLoading = true
      let new_item = await service.create({ category_key: this.category.key, display_order: this.display_order })
      this.isLoading = false
      if (new_item) {
        this.$router.push({ name: 'pronun-lesson-detail', params: { lesson_id: new_item._id } })
      }
    },
  },
}
</script>
