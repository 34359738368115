<template>
  <div class="page-content">
    <page-breadcrumb title="Pronun" class="mb-3" />

    <category-card v-for="(category) in categories" :key="category._id"
      :category="category" class="mt-1"
    />

  </div>
</template>
<script>
import service from '../service'
import CategoryCard from './_components/CategoryCard.vue'

export default {
  components: {
    CategoryCard,
  },
  data() {
    return {
      categories: [],
    }
  },
  created() {
    this.getCategoryList()
  },
  methods: {
    async getCategoryList() {
      let list = await service.getCategoryList()
      this.categories = list
    },
  },
}
</script>
